<template lang="pug">
TableV2(
  :headers="headers"
  :items="Array.isArray(items) ? items : items.results"
  :pageCount="items.count"
  :currentPage="items.current"
  :querySearch="getData"
  :loading="isLoading"
  isHiddenTableSearch
  isHiddenTableCount
  :isHiddenPageCount="!items.count")
</template>
<script>
import { mapActions, mapState } from 'vuex'
export default {
  name: 'DirectoryBackoffice',
  computed: {
    ...mapState({
      items: state => state.directory.selectedDictionary,
      isLoading: state => state.directory.isLoading
    }),
    headers () {
      const headers = [
        { value: 'id', text: this.$t('id') },
        { value: 'name_ukr', text: this.$t(this.$route.params.dictionaryType.toLowerCase()) }
      ]
      if (this.$route.params.dictionaryType === 'MedInstitution') {
        headers.splice(1, 1, { value: 'value', text: this.$t('MedInstitution'.toLowerCase()) })
      } else if (this.$route.params.dictionaryType === 'CrewingCompany') {
        headers.push({ value: 'group_name', text: this.$t('group') })
      }
      return headers
    }
  },
  methods: {
    ...mapActions(['getListDictionary']),
    async getData (params) {
      const { dictionaryType } = this.$route.params
      await this.getListDictionary({ dictionaryType, params })
    }
  }
}
</script>
